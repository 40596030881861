export default class Crypt {
  // const crypto = require("crypto");
  // const algorithm = "aes-256-cbc";
  // const key = crypto.randomBytes(32);
  // const iv = crypto.randomBytes(16);

  // encrypt(text) {
  //   let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  //   let encrypted = cipher.update(text);
  //   encrypted = Buffer.concat([encrypted, cipher.final()]);
  //   return { iv: iv.toString("hex"), encryptedData: encrypted.toString("hex") };
  // }
  // decrypt(cryptedtext) {
  //   let iv = Buffer.from(text.iv, "hex");
  //   let encryptedText = Buffer.from(text.encryptedData, "hex");
  //   let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  //   let decrypted = decipher.update(encryptedText);
  //   decrypted = Buffer.concat([decrypted, decipher.final()]);
  //   return decrypted.toString();
  // }

  // encrypt(text) {

  //   const crypto = require("crypto");
  //   // const secret = "abcdefg";
  //   const hash = crypto
  //     .createHmac("sha256", this.secret)
  //     .update("I love cupcakes")
  //     .digest("hex");
  //   // console.log(hash);
  //   return hash;
  // }
  // decrypt(encryptedtext) {
  //   const crypto = require("crypto");
  //   // const secret = "abcdefg";
  //   const hash = crypto
  //     .createHmac("sha256", this.secret)
  //     .update("I love cupcakes")
  //     .digest("hex");
  //   // console.log(hash);
  // }

  // see: https://www.npmjs.com/package/node-crypt
  secret = {
    key: "6e0ffd05720b7263d08e96e25f6e9ab917cad0cd79267332e75389e5d9680c26",
    hmacKey: "91eb3e3c9dfc169d3fcf6e62e9e6598c8a296eec0a6df04ffdb8e10dff7b36ba",
  };

  encrypt(unencryptedValue) {
    const Crypto = require("node-crypt");
    const crypto = new Crypto(this.secret);
    // Encrypt it
    return crypto.encrypt(unencryptedValue);
  }

  decrypt(encryptedValue) {
    return this.decryptFixed(encryptedValue);

    // const Crypto = require("node-crypt");
    // const crypto = new Crypto(this.secret);
    // // Decrypt it
    // return crypto.decrypt(encryptedValue);
  }

  // Temporary fix until PR is accepted
  // see: https://github.com/Stono/node-crypt/pull/8
  constantTimeCompare = function (val1, val2) {
    var sentinel;
    if (val1.length !== val2.length) {
      return false;
    }
    for (var i = 0; i <= val1.length - 1; i++) {
      sentinel |= val1.charCodeAt(i) ^ val2.charCodeAt(i);
    }
    return sentinel === 0;
  };

  decryptFixed(cipherText) {
    // const Crypto = require("node-crypt");
    const cryptoRaw = require("crypto");

    const options = this.secret;

    const algo = "aes-256-ctr";
    const hmacAlgo = "sha256";
    const key = Buffer.from(options.key, "hex");
    const hmacKey = Buffer.from(options.key, "hex");

    var cipherBlob = cipherText.split("|");
    var ct = cipherBlob[0];
    var IV = new Buffer(cipherBlob[1], "hex");
    var hmac = cipherBlob[2];
    var decryptor;

    const chmac = cryptoRaw.createHmac(hmacAlgo, hmacKey);
    chmac.update(ct);
    chmac.update(IV.toString("hex"));

    if (!this.constantTimeCompare(chmac.digest("hex"), hmac)) {
      throw new Error("Encrypted Blob has been tampered with.");
    }

    decryptor = cryptoRaw.createDecipheriv(algo, key, IV);
    const decryptedText = decryptor.update(ct, "hex", "utf8");
    return decryptedText + decryptor.final("utf8");
  }
}
